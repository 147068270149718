<template>
	<div class="member-list">


		<el-card class="filter-container" shadow="never">
			<div style="margin-top: 15px">
				<el-form :inline="true" size="small" label-width="100px">
                    <el-row>
                        <el-form-item label="微信昵称：">
                            <el-input style="width: 300px" v-model="keyword" placeholder="微信昵称"></el-input>
                        </el-form-item>

                        <el-form-item label=" " style="float: right;">
                            <el-button type="primary" size="small" @click="getonelist()">
                                查询
                            </el-button>
                            <el-button type="info"   size="small" @click="clearlist">
                                重置
                            </el-button>
                        </el-form-item>
                    </el-row>
				</el-form>
			</div>
		</el-card>


		<el-card style="margin-top: 20px;" shadow="never">
			<el-row justify="space-between" type="flex">
				<el-col><i class="el-icon-tickets"></i><span>会员列表</span></el-col>
			</el-row>
		</el-card>
		<el-card class="filter-container" shadow="never" style="margin: 20px 0;">
			<el-table :data="list" style="width: 100%;">
				<el-table-column label="id" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.memberId }}</span>
					</template>
				</el-table-column>
				<el-table-column label="手机号" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.memberTel }}</span>
					</template>
				</el-table-column>

				<el-table-column label="头像" align="center">
					<template slot-scope="scope">
						<el-image style="width: 40px; height: 40px" :src="scope.row.memberHeadpic ">
							<div slot="error" class="image-slot">
								<i class="el-icon-picture-outline"></i>
							</div>

						</el-image>
					</template>
				</el-table-column>

				<el-table-column label="微信昵称" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.memberNickname }}</span>
					</template>
				</el-table-column>

				<el-table-column label="性别" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.memberSex==0">{{''}}</span>
						<span  v-if="scope.row.memberSex==1">{{ '男' }}</span>
						<span  v-if="scope.row.memberSex==2">{{  '女' }}</span>
						<span  v-if="scope.row.memberSex==3">{{ '未知'}}</span>
					</template>
				</el-table-column>

				<el-table-column label="注册时间" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.createTime }}</span>
					</template>
				</el-table-column>
				<el-table-column label="状态" align="center">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.memberOpen" @change="switchbtn(scope)">
						</el-switch>
					</template>
				</el-table-column>
        <el-table-column label="医生标识" align="center">
          <template slot-scope="scope">
            <span  v-if="scope.row.doctorFlag">医生</span>
            <span  v-if="!scope.row.doctorFlag">患者</span>
          </template>
        </el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<p>
							<el-button size="mini" type="text" @click.native.prevent="gomember_man(scope)">就诊人</el-button>
							<!-- <el-button size="mini" type="text">预约记录</el-button> -->
<!--              <el-button size="mini" type="text" @click.native.prevent="del(scope)">删除</el-button>-->
              <el-button size="mini" type="text" v-if="!scope.row.doctorFlag" @click.native.prevent="changDoctorMark(scope)">更改医生标识</el-button>
						</p>
					</template>
				</el-table-column>
			</el-table>
		</el-card>
		<el-pagination v-if="pageshow" :hide-on-single-page="hideonsinglepage" background :current-page="pageNo"
		 @current-change="pagebtn" :total="box.totalCount" :page-size="10" layout="prev, pager, next">
		</el-pagination>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				keyword: '', //搜索
				list: [], //循环列表 
				row: { //点击选中的
					plugAdtypeId: '',
					plugAdtypeName: '',
					plugAdtypeOrder: '',
				},
				box: [], //全部数据
				hideonsinglepage: false, //分页
				allocDialogVisible: false, //控制弹窗显示
				modalAppendToBody: false, //弹窗插入位置
				apiurl: '', //接口地址
				pageNo: 1, //页码
				pageshow: true
			}
		},
		methods: {
			// 查询
			getonelist(){
				const that=this;
				that.list=[];
				that.pageNo=1;
				that.getlist();
			},
			
			onSubmit() {
			},
			
			// 页数改变
			pagebtn(e) {
				const that = this;
				that.pageNo = e;
				that.getlist();
			},
			// 跳转到就诊人
			gomember_man(e){
				const that=this;
				that.$router.push({name: 'memberlistman',query:{memberId: e ?  e.row.memberId : ''}})
			},
      // 授权医生标识
      changDoctorMark(e){
        const that = this;
        that.$confirm('授权后该会员可以为患者开启现场预约通道，您确认要为该会员授权医生标识么?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          that.api('/admin/member/update_doctor_flag', {
            memberId: e.row.memberId,
            doctorFlag: true
          }).then(res => {
            if (res.code == 200) {
              that.$message.success("授权医生标识成功!");
              that.getlist();
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
			// 单个删除
			del(e) {
				const that = this;
        that.$confirm('您确认要删除该条会员信息么?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            that.api('/admin/member/delete', {
                memberId: e.row.memberId
            }).then(res => {
                if (res.code == 200) {
                    that.$message.success("删除成功!");
                    that.getlist();
                }
            })
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });
        });

			},
			
			// 按钮开关
			switchbtn(e){
				const that=this;
				that.api('/admin/member/state',{memberId:e.row.memberId}).then(res=>{
					if(res.code==200){
						that.getlist();
					}
				})
			},

			// 获取列表
			getlist() {
				const that = this;
				that.api('/admin/member/list', {
					pageSize: 10,
					pageNo: that.pageNo,
					memberNickname: that.keyword
				}).then(res => {
					that.box = res.data;              console.log(res.data)
					for (var i = 0; i < res.data.pageList.length; i++) {
						if (res.data.pageList[i].memberOpen == '1') {
							res.data.pageList[i].memberOpen = true
						} else {
							res.data.pageList[i].memberOpen = false
						}
					}
					that.list = res.data.pageList;
					that.pageNo = res.data.pageNo;
					this.pageshow = false; //让分页隐藏
					this.$nextTick(() => { //重新渲染分页
						this.pageshow = true;
					});
				})
			},
			// 重置搜索
			clearlist() {
				const that = this;
				that.keyword = "";
					that.pageNo=1;
				that.getlist();
			}



		},
		created() {
			const that = this;
			that.getlist();

		}
	}
</script>

<style lang="less" scoped>


</style>
